import { useEffect } from "react";
import "./App.css";
import { interactiveCursor } from "./utils/interactiveCursor";

const App = () => {
  useEffect(() => {
    interactiveCursor();
  }, []);

  return <canvas></canvas>;
};

export default App;
